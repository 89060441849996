/* globals searchList, getCurrentUser, getCurrentPage, showAlert, logOut, console */
/**
 *  Program functions for use when selecting a program from the menu and reading program data.
 *  Should be used to access all Storage items under 'settings/' prefix.
 */

/**
 *  @typedef {Object} program
 *  @property {string} cLabel The title of the program
 *  @property {string} cModule The programs module
 *  @property {string} cParam Parameters for the program, pipe delimited
 *  @property {string} cRunName
 *  @property {string} cRunPath
 *  @property {string} cType
 *  @property {number} iKeyID - The unique program / menu ID
 *  @property {number} iLevel
 *  @property {number} iOrder
 *  @property {number} iParentID
 *  @property {boolean} lEnabled
 *  @property {boolean} lProgram
 **/

/**
 * @description Save program parameters to session storage
 * @returns {boolean} True if the parameters were saved correctly.
 */
function saveProgramParameters() {
  "use strict";
  // get the program key from session storage, which is set when pressing a menu button.
    // IF "" then we have a problem and need to go back to the menu.
  var programKey = sessionStorage.getVariable('runtime/navigation');
  sessionStorage.removeItem('runtime/navigation'); // immediately remove the key in case back navigation occurs.
  console.log('Program Key: ', programKey);
  if (programKey === "") {
      showAlert("Program options could not be found. Please try again.", {canCancel: false, callback: function(){
          window.location.replace('./dsmenu.php'); // Replaces the current URL with the menu, this will prevent coming back here.
          }});
      return false;
  }
  // Log telemetry
    console.log('Telemetry: '+getCurrentPage()+'-'+programKey);
  // backup the key for program reloads. Also used later for telemetry.
    sessionStorage.setItem("runtime/reload-navigation", programKey);
  // Get the program for this based on the programKey.
  var program = getProgramFromProgramKey(programKey);
  if (program) {
      sessionStorage.setVariable("program/name",program.cLabel,true);
      sessionStorage.setVariable("program/transaction",findProgramParameter(program.cParam,"TT"),true);
      sessionStorage.setVariable("program/fields",findProgramParameter(program.cParam,"FP"),true);
      sessionStorage.setVariable("program/warehouseType",findProgramParameter(program.cParam,"WT"),true);
      sessionStorage.setVariable("program/type",findProgramParameter(program.cParam,"PT"),true);
      sessionStorage.setVariable("program/security",findProgramParameter(program.cParam,"PS"),true);
      sessionStorage.setVariable("program/sendsTransactions",findProgramParameter(program.cParam,"ST"),true);
      sessionStorage.setVariable("program/pollsAllocation",findProgramParameter(program.cParam,"PA"),true);
      sessionStorage.setVariable("program/allocationTypes",findProgramParameter(program.cParam,"AT"),true);
      sessionStorage.setVariable("program/inputParameter",findProgramParameter(program.cParam,"IP"),true);
      sessionStorage.setVariable("program/checksLocation",findProgramParameter(program.cParam,"CL"),true);
      sessionStorage.setVariable("program/vesselBarcodeType",findProgramParameter(program.cParam,"BT"), true);
      sessionStorage.setVariable("program/readerIgnored",findProgramParameter(program.cParam,"RI"), true);
      sessionStorage.setVariable("program/parameters",program.cParam,true);
      return true;
  } else {
    // Something went wrong finding the program. This should'nt be possible but throw anyway.
    showAlert("Program options could not be found. Please try again.", {canCancel: false, callback: function(){
        window.location.replace('./dsmenu.php'); // Replaces the current URL with the menu, this will prevent coming back here.
      }});
      return false;
  }
}

/**
 * @description safely reloads a page. Takes a backed up program key and sets it as if navigating from the menu.
 */
function reloadProgram() {
  "use strict";
  var reloadKey = sessionStorage.getItem("runtime/reload-navigation");
  if (reloadKey !== "") {
    // If the key was backed up safely then we have a value here to set and reload with.
    sessionStorage.removeItem("runtime/reload-navigation");
    sessionStorage.setItem('runtime/navigation', reloadKey);
  }
  // Now we reload, and if the key was safe then it will be set correctly for page load.
  window.location.reload();
}

/**
 * @description Returns the menu key for the program being run
 * @returns {Number|String} The key for the current program
 */
function getCurrentProgramKey() {
  "use strict";
  // Note that the current program is backed up into reload-navigation
  // This is key for preventing back navigation as loading a page from that looks for navigation and will fail.
  // Perhaps in the future we might rename this key to programKey.
  var reloadKey = sessionStorage.getItem("runtime/reload-navigation");
  if (reloadKey) {
    return reloadKey;
  } else {
    return 0;
  }
}

/**
 * @description Clear the reload navigation key from session storage to ensure a clean page load.
 */
function clearReloadProgramKey() {
  "use strict";
  sessionStorage.removeItem("runtime/reload-navigation");
}

/**
 * @description Clears all program parameters cleanly from SessionStorage
 */
function clearProgramParameters() {
    "use strict";
    sessionStorage.removeItem("program/name");
    sessionStorage.removeItem("program/transaction");
    sessionStorage.removeItem("program/fields");
    sessionStorage.removeItem("program/warehouseType");
    sessionStorage.removeItem("program/type");
    sessionStorage.removeItem("program/security");
    sessionStorage.removeItem("program/sendsTransactions");
    sessionStorage.removeItem("program/pollsAllocation");
    sessionStorage.removeItem("program/allocationTypes");
    sessionStorage.removeItem("program/inputParameter");
    sessionStorage.removeItem("program/checksLocation");
    sessionStorage.removeItem("program/vesselBarcodeType");
    sessionStorage.removeItem("program/readerIgnored");
    sessionStorage.removeItem("program/parameters");
}

/**
 * @description generic function which pulls the query string value of a given query string parameter
 * @param {string} name The query parameter name
 * @returns {string} The query parameter value if found, otherwise blank.
 */
function getUrlParameter(name) {
    "use strict";
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

/**
 * @description By using a program key, locates the program record under the current users menu
 * @param {string|number} programKey The unique key for the program required
 * @returns {null|program} The program found from the users menu, null if not found.
 */
function getProgramFromProgramKey(programKey) {
    "use strict";
    // Get the current user and filter their menu to find the key and the program.
    var currentUser = getCurrentUser();
    var programs = currentUser.menu.filter(function(el) {
        return el.iKeyID === programKey*1; // cast program key to number
    });
    if (programs.length === 1) {
        return programs[0];
    } else {
        //Throw an error because the program key was not found in the users menu.
        showAlert("Invalid access to Program. You will now be logged out.", {callback: function() {
            logOut(false);
            }});
        return null;
    }
}

/**
 * @description Finds the value of a Program Parameter from a programs list of parameters - Internal to program.js ONLY
 * @param {String} programParameters String List of program parameters
 * @param {String} paramName The name of the parameter to search for
 * @returns {String}
 */
function findProgramParameter(programParameters, paramName) {
  "use strict";
  var params = programParameters.split("|");
  var result = params.indexOf(paramName);
  if (result >= 0) return params[result + 1];
  else return "";
}

/**
 * @description Generic function allowing access to find any program parameter stored in Session storage whilst in a program.
 * @param {String} paramName The parameter code to find
 * @returns {String} The value of the parameter, empty string is returned when not found.
 */
function getProgramParameter(paramName) {
	"use strict";
	var parameters = sessionStorage.getVariable("program/parameters");
	return findProgramParameter(parameters,paramName);
}

/**
 * @description Checks to see if the current program has the given parameter. [Legacy]
 * @param {String} param
 * @returns {boolean}
 */
function hasProgramParameter(param) {
  "use strict";
  if (sessionStorage.getItem("program/fields"))
  return searchList(param, sessionStorage.getVariable("program/fields").split(","));
  else return searchList(param, localStorage.getVariable("progparam").split(",")); // Compatibility

}

/**
 * @description determines if the current program is a Picking program. [Legacy]
 * @returns {boolean}
 */
function isPickingProgram() {
  "use strict";
  return getProgramType() === "PKNG";
}

/**
 * @description determines if the current program is the program asked for. [Legacy]
 * @param {string} programType The program asked for.
 * @returns {boolean}          True if matched.
 */
function isThisProgramType(programType) {
  "use strict";
  return getProgramType() === programType.toUpperCase();
}

/**
 * @description Gets the name of the current program
 * @returns {String} Name of the program
 */
function getProgramName() {
  "use strict";
  if (sessionStorage.getItem("program/name"))
  return sessionStorage.getVariable("program/name");
  else return localStorage.getVariable("prog"); //Compatibility
}

function getProgramType() {
  "use strict";
  if (sessionStorage.getItem("program/type"))
  return sessionStorage.getVariable("program/type");
  else return localStorage.getVariable("progtype"); //compatibility
}

/**
 * @description Get the type of barcode the program can scan
 * @returns {string} Type of barcode that can be scanned for a vessel (Fields that say scan cask). Default 'ALL' allows all types
 */
function getProgramVesselBarcodeType() {
	"use strict";
	var type = sessionStorage.getVariable("program/vesselBarcodeType");
	return (type !== "")? type : "ALL";
}

function getProgramReaderIgnored() {
	"use strict";
	return sessionStorage.getVariable("program/readerIgnored");
}

function getProgramSecurity() {
  "use strict";
  var value = sessionStorage.getVariable("program/security").toUpperCase();
  return (value === "TRUE" || value === "YES");
}

function getProgramTransactionCode() {
  "use strict";
  if (sessionStorage.getItem("program/transaction"))
  return sessionStorage.getVariable("program/transaction");
  else return localStorage.getVariable("progtrans"); // Compatibility
}

function getProgramFields() {
  "use strict";
  if (sessionStorage.getItem("program/fields"))
    return sessionStorage.getVariable("program/fields");
  else return localStorage.getVariable("progparam"); // Compatibility
}

function getProgramWarehouseType() {
  "use strict";
  if (sessionStorage.getItem("program/warehouseType"))
  return sessionStorage.getVariable("program/warehouseType");
  else return localStorage.getVariable("progwhstype"); //Compatibility
}

function getProgramAllocationTypes() {
  "use strict";
  var types = sessionStorage.getVariable("program/allocationTypes");
  types = (types === "")? "CASK,ORDERLINE,DESPATCH,RECEIPT": types;
  return types;
}

function getProgramInputParameter() {
  "use strict";
  return sessionStorage.getVariable("program/inputParameter");
}

function doesProgramPollAllocationChanges() {
  "use strict";
  //Compatibility
  var value = (sessionStorage.getItem("program/pollsAllocation")) ? sessionStorage.getVariable("program/pollsAllocation").toUpperCase() : localStorage.getVariable("programPollsAllocation").toUpperCase();
  return  (value === "YES" || value === "TRUE");
}

function doesProgramSendTransactions() {
  "use strict";
  //Compatibility
  var value = (sessionStorage.getItem("program/sendsTransactions")) ? sessionStorage.getVariable("program/sendsTransactions").toUpperCase(): localStorage.getVariable("programSendsTransactions").toUpperCase();
  return (value === "TRUE" || value === "YES");
}

function doesProgramCheckLocation() {
	"use strict";
	var value = sessionStorage.getVariable("program/checksLocation").toUpperCase();
	return (value === "TRUE" || value === "YES");
}

function setProgramToSendTransactions() {
  "use strict";
  sessionStorage.setVariable("program/sendsTransactions","YES",false);
}

function isApplicationInProgram() {
	"use strict";
	return window.location.href !== './dsmenu.php';
}

function isMenuPage() {
    "use strict";
    return getCurrentPage() === "dsmenu";
}

function isOneOfThesePages(pages) {
    "use strict";
    return pages.indexOf(getCurrentPage()) > -1;
}


